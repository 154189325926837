<template>
  <el-dialog 
    title="详情"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
    width="600px"
  >
    <el-form :model="formData" ref="form" label-width="150px" size="mini" v-loading="loading">
      <!-- <el-form-item prop="userName" label="用户昵称">
        <div> {{formData.userName}} </div>
      </el-form-item>
      <el-form-item prop="avatarUrl" label="头像">
        <template>
          <el-image
            style="width: 100px; height: 100px"
            :src="formData.avatarUrl"
            fit="cover"
            :preview-src-list="[formData.avatarUrl]"
          />
        </template>
      </el-form-item>
      <el-form-item prop="phone" label="手机号">
        <div> {{formData.phone}} </div>
      </el-form-item>
      <el-form-item prop="mailbox" label="邮箱">
        <div> {{formData.mailbox}} </div>
      </el-form-item>
      <el-form-item prop="balance" label="个人账户余额">
        <div> {{formData.balance}} </div>
      </el-form-item>
      <el-form-item prop="avatarUrl" label="身份在图片">
        <div class="preview-image">
          <div v-for="(item,index) of [formData.portraitUrl,formData.emblemUrl]" :key="index" class="preview-image_item">
            <el-image
              style="width: 100px; height: 100px"
              :src="item"
              fit="cover"
              :preview-src-list="[formData.portraitUrl,formData.emblemUrl]"
            />
          </div>
        </div>
      </el-form-item>
      <el-form-item prop="birthday" label="生日">
        <div> {{formData.birthday}} </div>
      </el-form-item>
      <el-form-item prop="gender" label="性别">
        <div> {{formData.gender == 1 ? '男' : '女'}} </div>
      </el-form-item> -->

      <el-form-item prop="userName" label="用户昵称">
        <div> {{formData.userName}} </div>
      </el-form-item>
      <el-form-item prop="userName" label="手机号">
        <div> {{formData.phone}} </div>
      </el-form-item>
      <el-form-item prop="mailbox" label="邮箱">
        <div> {{formData.mailbox}} </div>
      </el-form-item>
      <el-form-item prop="birthday" label="生日">
        <div> {{formData.birthday}} </div>
      </el-form-item>
      <el-form-item prop="gender" label="性别">
        <div> {{formData.gender == 1 ? '男' : '女'}} </div>
      </el-form-item>
    </el-form> 
    <gl-flex>
      <el-button @click="handleClose" >关闭</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
import {getUserById} from '@/api/backendall/member'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    itemId: {
      type: String,
      default: '',
    },
  },
  data(){
    return{
      formData: {},
      loading: false,
    }
  },
  watch: {
    visible: {
      handler: function(newVal){
        newVal && this.itemId && this.loadAccountById()
      },
    }
  },
  methods: {
    async loadAccountById(){
      this.loading = true
      const res = await getUserById(this.itemId)
      this.formData = res.data
      this.loading = false
    },
    handleClose(){
      this.$emit('update:visible', false)
      this.formData = {}
      this.$refs.form.resetFields()
    },
  },
}
</script>

<style lang="scss" scoped>
.preview-image{
  display: flex;
  &_item{
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
</style>