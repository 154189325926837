<template>
  <el-dialog 
    title="修改"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
    width="600px"
  >
    <gl-wrap right="30px">
      <el-form :model="formData" ref="form" label-width="150px" size="mini" v-loading="loading">
        <el-form-item prop="userName" label="用户昵称" verify>
          <el-input v-model="formData.userName" placeholder="请输入用户昵称"></el-input>
        </el-form-item>
        <!-- <el-form-item prop="avatarUrl" label="头像" verify>
          <gl-upload-images v-model="formData.avatarUrl" :limit="1"></gl-upload-images>
        </el-form-item> -->
        <el-form-item prop="phone" label="手机号" verify>
          <el-input v-model="formData.phone" placeholder="请输入手机号" :maxlength="11"></el-input>
        </el-form-item>
        <el-form-item prop="mailbox" label="邮箱" verify>
          <el-input v-model="formData.mailbox" placeholder="请输入邮箱"></el-input>
        </el-form-item>
        <el-form-item prop="birthday" label="生日" verify>
          <el-date-picker
            v-model="formData.birthday"
            type="date"
            value-format="yyyy-MM-dd"
            :default-value="new Date('1990-01-01').getTime()"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="gender" label="性别" verify>
          <gl-option v-model="formData.gender" :list="genderList"></gl-option>
        </el-form-item>

      </el-form> 
    </gl-wrap>
    <gl-flex>
      <el-button type="primary" @click="onSubmit" >确认</el-button>
      <el-button @click="handleClose" >关闭</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
import {getUserById, updataUser} from '@/api/backendall/member'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    itemId: {
      type: String,
      default: '',
    },
  },
  data(){
    return{
      formData: {},
      loading: false,
      genderList: [
        {name: '男', value: '1'},
        {name: '女', value: '2'}
      ],
    }
  },
  watch: {
    visible: {
      handler: function(newVal){
        newVal && this.itemId && this.getAccount()
      }
    }
  },
  methods: {
    onSubmit(){
      this.$refs.form.validate(async valid => {
        if(!valid) return false
        this.loading = true
        
        let params = {
          userId: this.itemId,
          userName: this.formData.userName,
          phone: this.formData.phone,
          mailbox: this.formData.mailbox,
          birthday: new Date(this.formData.birthday).getTime(),
          gender: Number(this.formData.gender),
        }
        const res = await updataUser(params)
        this.loading = false
        this.$message.success('操作成功')
        this.handleClose()
        this.$emit('reload')
      })
    },
    async getAccount(){
      this.loading = true
      const res = await getUserById(this.itemId)
      res.data.gender = String(res.data.gender)
      this.formData = {
        userId: res.data.userId,
        userName: res.data.userName,
        // avatarUrl: '',
        // emblemUrl: '', // 身份证徽章面
        // portraitUrl: '',
        // balance: '',
        phone: res.data.phone,
        mailbox: res.data.mailbox,
        birthday: res.data.birthday,
        gender: res.data.gender,
      }
      this.loading = false
    },
    handleClose(){
      this.$emit('update:visible', false)
      this.formData = {}
      this.$refs.form.resetFields()
    },
  },
}
</script>

<style>

</style>